export class Tooltip extends HTMLElement {
  constructor () {
    super()
  }

  connectedCallback () {
    this.tooltips = document.querySelectorAll('tool-tip')
    this.innerHTML = `
            <i style="font-size: 1.5rem" class="far fa-question-circle"></i>
            <div class="content">
                ${this.innerHTML}
            </div>
        `
    this.loadStyle()
    document.addEventListener('click', this.onClick.bind(this))
    this.addEventListener('click', e => {
      e.preventDefault()
      e.stopPropagation()
      this.classList.toggle('visible')
      this.tooltips.forEach(tooltip =>
        tooltip !== this && tooltip.classList.contains('visible')
          ? tooltip.classList.remove('visible')
          : ''
      )
    })
  }

  onClick (e) {
    this.tooltips.forEach(tooltip => {
      if (
        e.target.closest('tool-tip') !== tooltip &&
        tooltip.classList.contains('visible')
      )
        tooltip.classList.toggle('visible')
    })
  }

  loadStyle () {
    const template = document.createElement('template')
    template.innerHTML = `<style>
            :root {
             --main-visibility: hidden;
             --main-opacity: 0;
            }
            tool-tip {
                overflow-wrap: break-word;
                cursor: pointer;
            }
            tool-tip .content {
                visibility: var(--main-visibility);
            }
            tool-tip.visible, 
            tool-tip.visible > * {
                --main-opacity: 1;
                --main-visibility: visible;
            }
        </style>`
    this.appendChild(template.content.cloneNode(true))
  }
}
